<template>
  <v-app>
    <v-main class="general--text px-4">
      <v-snackbar
        v-model="snackWithButtons"
        bottom
        left
        timeout="-1"
      >
        {{ snackWithBtnText }}
        <template #action="{ attrs }">
          <v-btn
            text
            color="#00f500"
            v-bind="attrs"
            @click.stop="refreshApp"
          >
            {{ snackBtnText }}
          </v-btn>
          <v-btn
            icon
            class="ml-4"
            @click="snackWithButtons = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <MainNavbar />
      <v-container class="py-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MainNavbar from "@/components/partials/MainNavbar.vue";

export default {
  name: "App",
  components: {
    MainNavbar,
  },

  data() {
    return {
      refreshing: false,
      registration: null,
      snackBtnText: '',
      snackWithBtnText: '',
      snackWithButtons: false,
    };
  },

  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },

  methods: {
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.snackBtnText = 'Recharger';
      this.snackWithBtnText = 'Nouvelle version disponible!';
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
};
</script>


<style lang="scss">
a {
  text-decoration: none;
}

body {
  font-size: 1rem;
}

.menu {
  text-align: center;
}

img.image-menu {
  max-height: 40px;
}

.text-smaller {
  font-size: 0.9rem !important;
}

.help {
  font-size: 0.8em;
  span {
    font-weight: bold;
  }
}
</style>