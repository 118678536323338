const ManagedUsersList = () =>
  import(/* webpackChunkName: "ManagedUsersList" */ "@/components/board/managedUsers/ManagedUsersList.vue");

const ManagedUsersDetails = () =>
  import(/* webpackChunkName: "ManagedUsersDetails" */ "@/components/board/managedUsers/ManagedUserDetails.vue");

export default[
    {
        path:"/",
        name:"ManagedUsersList",
        component : ManagedUsersList
    },
    {
        path:"/:uuid",
        name:"ManagedUsersDetails",
        component : ManagedUsersDetails
    }
]