import { Model } from "@vuex-orm/core";
import Action from "@/models/Action";

import { CarService } from "@/services/CarService";

let carLoadingPromise = {};

export default class Car extends Model {
  static entity = "cars";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.string(null),
      full_numberplate: this.string(""),
      brand: this.string(""),
      model: this.string(""),
      version: this.attr(""),
      linked_user_profile: this.attr(null),
      actions: this.hasMany(Action, "car"),
      cost_center: this.attr(null),
      provider: this.attr("")
    };
  }

  static async getOrFetch(id){
    if(this.find(id)){
      return this.find(id)
    }else{

      if (!carLoadingPromise[id]){
        carLoadingPromise[id] = CarService.getCarById(id)
      }
      const car = await carLoadingPromise[id]

      this.insert({ data: car });
      return this.find(id)
    }
  }

  static async getOrFetchByUserProfile(uuid){
    const carsFromStore = this.query().where((car) => {
      return car.linked_user_profile.uuid === uuid
    }).get()

    if(carsFromStore.length){
      return carsFromStore
    }else{
      try{

        if (!carLoadingPromise[uuid]){
          carLoadingPromise[uuid] = CarService.getCarByUserProfile(uuid)
        }
        const carFromDB = await carLoadingPromise[uuid]

        if(!carFromDB){
          return null
        }
        this.insertOrUpdate({data:carFromDB})
        return carFromDB
     }catch(error){
        console.log('error:', error)
        return null
     }
    }
  }

}
