import Vue from "vue";
import VueRouter from "vue-router";
import fetchUserData from "./fetchUserData"

import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

import BoardChildrens from '@/router/board/BoardChildrens'
import CarDamagesChildrens from '@/router/carDamages/CarDamagesChildrens'

const Home = () =>
  import(/* webpackChunkName: "Home" */ "@/components/Home.vue");
const Inventory = () =>
  import(/* webpackChunkName: "Inventory" */ "@/components/Inventory.vue");
const Accident = () =>
  import(/* webpackChunkName: "Accident" */ "@/components/Accident.vue");
const Robbery = () =>
  import(/* webpackChunkName: "Roberry" */ "@/components/Robbery.vue");
const Mechanicals = () =>
  import(/* webpackChunkName: "Mechanicals" */ "@/components/Mechanicals.vue");
const ContactManager = () =>
  import(/* webpackChunkName: "ContactManager" */ "@/components/ContactManager.vue");

const Fuel = () =>
  import(/* webpackChunkName: "Fuel" */ "@/components/Fuel.vue");
const Bump = () =>
  import(/* webpackChunkName: "Bump" */ "@/components/Bump.vue");

 const Error = () =>
  import(/* webpackChunkName: "Error" */ "@/components/Error.vue");

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OIDCCallback");

//Subrouters
const Board = () =>
  import(/* webpackChunkName: "Board" */ "@/components/Board.vue");
const CarDamages = () =>
  import(/* webpackChunkName: "CarDamages" */ "@/components/CarDamages.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true
    }
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
    meta: {
      isPublic: true
    }
  },
  {
    path: "/car-damages/:incidentType",
    component: CarDamages,
    children: CarDamagesChildrens
  },
  {
    path: "/board",
    component : Board,
    children : BoardChildrens
  },
  {
    path: "/accident",
    component : Accident,
    name : "Accident"
  },
  {
    path: "/fuel",
    component: Fuel,
    name:"Fuel"
  },
  {
    path: "/bump",
    component: Bump,
    name:"Bump"
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory
  },
  {
    path: "/robbery",
    name: "Robbery",
    component: Robbery
  },
  {
    path: "/mechanicals",
    name: "Mechanicals",
    component: Mechanicals
  },
  {
    path: "/contact-manager",
    name: "ContactManager",
    component: ContactManager
  },
];

const router = new VueRouter({
  mode: "history",
  routes
});


router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidc"));
router.beforeEach(fetchUserData(store));

export default router;
