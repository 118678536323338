export const SOCOTEC_GROUPS = {
    DEVELOPER: "Developer",
    SOCIO_DEV: "SocioDev",
    ADMIN_CAR_CHECK: "AdminCarCheck",
    
};
export const DEVELOPERS_GROUPS = [
    SOCOTEC_GROUPS.DEVELOPER,
    SOCOTEC_GROUPS.SOCIO_DEV
];
export const APP_GROUPS = [
    SOCOTEC_GROUPS.ADMIN_CAR_CHECK,
];
