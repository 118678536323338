const DeclarationsList = () =>
  import(/* webpackChunkName: "DeclarationsList" */ "@/components/board/DeclarationsList");

const DeclarationsExport = () =>
 import(/* webpackChunkName: "DeclarationsExport" */ "@/components/board/DeclarationsExport");

 const ManagedUsers = () =>
  import(/* webpackChunkName: "ManagedUsers" */ "@/components/board/ManagedUsers");

import ManagedUsersChildrens from "@/router/board/managedUsers/ManagedUsersChildrens"

export default[
  {
    path:"/declarations",
    name:"DeclarationsList",
    component:DeclarationsList
  },
  {
    path:"/export",
    name:"DeclarationsExport",
    component:DeclarationsExport
  },
  {
    path: "/managed-users",
    component:ManagedUsers,
    children : ManagedUsersChildrens
  }
]