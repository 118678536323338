import { Model } from "@vuex-orm/core";
import UserProfile from "@/models/UserProfile";
import { ActionService } from "@/services/ActionService";

export default class Action extends Model {
  static entity = "actions";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.string(null),
      action_category: this.string(""),
      action_subcategory: this.attr(""),
      action_data: this.attr(""),
      need_manager_validation: this.boolean(false),
      validation_response: this.attr(""),
      is_canceled: this.boolean(false),
      is_finished: this.boolean(false),
      created_by: this.attr(null),
      user_profile: this.hasOne(UserProfile,'uuid','created_by'),
      car: this.attr(null),
      manager: this.attr(null),
      manager_profile: this.hasOne(UserProfile,'uuid','manager'),
      created_at: this.attr(null),
      modified_at: this.attr(null),
      validation_response_at: this.attr(null),
      order_number: this.number().nullable()
    };
  }

  static async getOrFetch(params){
    const actionFromStore=this.query().orderBy('created_at','desc').get();
    if(actionFromStore.length){
      return actionFromStore
    }else{
      try{
        const actionsFromDB = await ActionService.getActions(params)
        if(!actionsFromDB){
          return null
        }
        this.insertOrUpdate({data:actionsFromDB.results})
        return actionsFromDB
     }catch(error){
        console.log('error:', error)
        return null
     }
    }
  }
}
