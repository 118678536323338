import appCarCheckClient from '@/setup/axios.js';
import { saveAs } from "file-saver";
const urlEntryPoint = `/car/actions/`;

export const ActionService = {
	async addAction(action) {
		try {
			const response = await appCarCheckClient.post(urlEntryPoint, action);
			return response.data;
		} catch (error) {
			console.log('Errors', error);
		}
		return false;
	},

	async getActions(params) {
		try {
			const response = await appCarCheckClient.get(urlEntryPoint, { params });
			return response.data;
		} catch (error) {
			console.log('Errors', error);
		}
		return false;
	},

	async getActionById(id) {
		try {
			const response = await appCarCheckClient.get(`${urlEntryPoint}${id}`);
			return response.data;
		} catch (error) {
			console.log('Errors', error);
		}
		return false;
	},

	async updateAction(action) {
		try {
			const results = await appCarCheckClient.put(`${urlEntryPoint}${action.uuid}/`, action);
			return results
		} catch (error) {
			console.log("Errors", error);
			throw (error)
		}

	},

	async getExportAction(daterange) {
		try {
			const response = await appCarCheckClient.get(`${urlEntryPoint}export_actions?daterange=${daterange}`, {responseType: 'blob'});
			const filename = response.headers["content-disposition"].split("filename=")[1]
			return saveAs(new Blob([response.data]), filename)
		} catch (error) {
			console.log('Errors', error);
		}
		return false;
	},

};
