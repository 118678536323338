import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./setup/vuetify";
import { i18n } from "./setup/i18n-setup";
import './registerServiceWorker'

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  i18n,
  computed: {
    isDevEnv() {
      return process.env.NODE_ENV === "development";
    },
  },
  render: h => h(App)
}).$mount("#app");
