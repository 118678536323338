import "@mdi/font/css/materialdesignicons.css";
import { i18n } from "@/setup/i18n-setup";
import Vue from "vue";
import Vuetify, { VApp, VBtn, VContainer, VSpacer, VDatePicker, VIcon, VTooltip } from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VApp,
    VBtn,
    VContainer,
    VSpacer,
    VDatePicker,
    VIcon,
    VTooltip
  },
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#fcb902",
        secondary: "#0097DC",
        general:"#130F49",
        third: "#0097DC",
        bimaction: "#30374b",
        "header-primary": "#28669a",
        "light-blue": "#00ace8",
        "body-color": "#94979a",
        "menu-color":"#f3f7ff",
        "menu-color-darker":"#e5eeff",
        "base":"#130Ffd"
      },
      dark: {
        primary: "#00669e",
        secondary: "#fcb902",
        third: "#00ace8",
        bimaction: "#30374b",
        "header-primary": "#28669a",
        "light-blue": "#00ace8",
        "body-color": "#94979a",
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
});
