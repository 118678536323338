<template>
  <v-app-bar
    app
    color="white"
    flat
    class="px-4"
  >
    <v-app-bar-nav-icon
      :to="{ name: 'Home' }"
      color="white"
      width="120"
    >
      <v-img
        src="@/assets/images/socotec-logo.svg"
        max-width="120"
        max-height="40"
      />
    </v-app-bar-nav-icon>

    <router-link 
      :to="{ name: 'Home' }"
      class="size"
    >
      <v-toolbar-title class="pr-4 general--text font-weight-bold">
        Carcheck
      </v-toolbar-title>
    </router-link>
    <v-spacer />
    <v-btn
      v-if="oidcUser && $root.isDevEnv"
      depressed
      text
      small
      @click="logout"
    >
      Deconnexion
    </v-btn>

    <router-link :to="{ name: 'DeclarationsList' }">
      <v-badge
        :content="badgeContent"
        :value="badgeContent"
        color="red"
        overlap
      >
        <v-img
          src="@/assets/images/icons/clipboard.svg"
          max-width="30"
          max-height="30"
        />
      </v-badge>
    </router-link>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ActionModel from "@/models/Action";
import UserProfileModel from "@/models/UserProfile";

export default {
  name: "MainNavbar",

  data() {
    return {
      titleApp: process.env.VUE_APP_NAME,
      badgeManagerValue: false,
      actions: null,
      user: null,
    };
  },
  computed: {
    ...mapGetters("oidc", ["oidcUser"]),
    userProfile() {
      return UserProfileModel.query().first();
    },
    badgeContent() {
      const actionsToValidate = ActionModel.query()
        .where("is_finished", false)
        .where("is_canceled", false)
        .where("need_manager_validation", true)
        .with("users")
        .get();

      const nbActionsToValidate = actionsToValidate.filter(
        (action) =>
          action.created_by != this.userProfile.uuid &&
          action.validation_response == null
      ).length;

      const actionsValidated = ActionModel.query()
        .where("is_finished", false)
        .where("need_manager_validation", true)
        .with("users")
        .get();

      const nbActionsValidated = actionsValidated.filter(
        (action) =>
          action.created_by === this.userProfile.uuid &&
          action.validation_response != null
      ).length;

      return nbActionsToValidate + nbActionsValidated;
    },
  },
  methods: {
    ...mapActions("oidc", ["signOutOidc"]),
    ...mapMutations("oidc", ["setOidcError"]),
    async logout() {
      try {
        await this.signOutOidc();
      } catch (err) {
        this.setOidcError(err);
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn__content {
  margin: 0;
  padding: 0;
}
.size{
  width: 100%;
  text-align: end;;
}
</style>
