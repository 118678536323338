import User from "@/models/UserProfile";

const urlEntryPoint = `/users/user-profiles`

export const UserService={

    async getUserByUsermanagementUuid(usermanagementUuid){
        const url = `${urlEntryPoint}/?user__usermanagement_uuid=${usermanagementUuid}`;
        try {
            const results = await User.api().get(url,{ dataKey: "results" });

            if(results.response.data.results.length ==null){
                return null
            }
            return results.response.data.results
        } catch (error) {
            console.log("Errors", error);
            return null
        }
    },

    async getUserById(id){
        try {
            const results = await User.api().get( `${urlEntryPoint}/${id}/`);
            return results.response.data
        } catch (error) {
            console.log("Errors", error);
        }
        return false
    },

    async getUsers(params){
        try {
            const results = await User.api().get( `${urlEntryPoint}/`,params);
            return results.response.data
        } catch (error) {
            console.log("Errors", error);
        }
        return false
    },

    async updateUser(userData){
        try {
            const results = await User.api().put(`${urlEntryPoint}/${userData.uuid}/`,userData);
            return results
        } catch (error) {
            console.log("Errors", error);
            throw(error)
        }

    },


}