import intersection from "lodash/intersection";
import { Model } from "@vuex-orm/core";
import { DEVELOPERS_GROUPS, APP_GROUPS } from "@/data/permissions";
import Car from "@/models/Car";
import Action from "@/models/Action";

import { UserService } from "@/services/UserService";
import user from "@socotec.io/socio-grpc-api/services/amos_back/js/user_grpc_web_pb";

const userLoadingPromise = {}
export default class User extends Model {
  static entity = "user_profile";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.string(null),
      cars: this.hasMany(Car, "linked_user_profile"),
      actions: this.hasMany(Action, "created_by"),
      manager: this.attr(null),
      email: this.string(""),
      is_superuser: this.boolean(false),
      groups: this.attr([]),
      socotec_code: this.attr(null),
      telephone_number: this.string(null),
      user: this.attr(null),
      is_manager: this.boolean(false),
      managed_users: this.attr(null),
      managed_users_profiles: this.hasManyBy(User, "managed_users")
    };
  }

  static async getOrFetchById(id) {
    const userFromStore = this.find(id)
    if (userFromStore) {
      return userFromStore
    } else {
      if (!userLoadingPromise[id]) {
        userLoadingPromise[id] = UserService.getUserById(id)
      }
      const userFromDB = await userLoadingPromise[id]
      this.insert({ data: userFromDB })
      return userFromDB
    }
  }

  static async getOrFetchByUsermanagementUuid(usermanagementUuid) {
    const userFromStore = this.query().where((user_profile) => {
      if(user_profile.user) {
        return user_profile.user.usermanagement_uuid == usermanagementUuid
      }
    }).get()
    if (userFromStore.length) {
      return userFromStore
    } else {
      try {
        const userFromDB = await UserService.getUserByUsermanagementUuid(usermanagementUuid)
        if (!userFromDB) {
          return null
        }
        const user = await this.insertOrUpdate({ data: userFromDB[0] })
        return user.user_profile[0]
      } catch (error) {
        console.log('error:', error)
        return null
      }
    }
  }

  isInGroup(groupToCheck) {
    return intersection(APP_GROUPS, this.user.groups).length > 0;
  }

  isDev() {
    return this.user.groups.some((group) => DEVELOPERS_GROUPS.includes(group));
  }

  isAdminCarCheck() {
    return this.isInGroup(APP_GROUPS);
  }

}
