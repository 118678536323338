import Vue from "vue";
import Vuex from "vuex";

// Vuex ORM
import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import { appCarCheckClient, initAppSafeAuth } from "@/setup/axios";
import { initCarCheckAuth } from "@/setup/socioGrpcClient"
import Car from "@/models/Car";
import Action from "@/models/Action";
import User from "@/models/UserProfile";

// Vuex OIDC
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "@/setup/OIDCSettings";

Vue.use(Vuex);

VuexORM.use(VuexORMAxios, { axios: appCarCheckClient });

const database = new VuexORM.Database();
database.register(User);
database.register(Car);
database.register(Action);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    oidc: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        removeUserWhenTokensExpire: false,
        dispatchEventsOnWindow: true
      },
      {
        userLoaded: user => {
          initAppSafeAuth(user.access_token);
          initCarCheckAuth(user.access_token);
        },
        userUnloaded: () => console.log("OIDC user is unloaded"),
        accessTokenExpiring: () => console.log("Access token will expire"),
        accessTokenExpired: async () => {
          console.log("Access token did expire");
          await tryRefreshSessionOrRedirect();
        },
        silentRenewError: () => console.log("OIDC user is unloaded"),
        automaticSilentRenewError: payload =>
          console.log("Automatic silent renew failed.", payload.error),
        userSignedOut: () => console.log("OIDC user is signed out"),
        oidcError: async payload => {
          console.log(`An error occured at ${payload.context}:`, payload.error);
        }
      }
    )
  },
  plugins: [VuexORM.install(database)]
});
