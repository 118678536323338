
import UserProfileModel from "@/models/UserProfile";

export default store => {
  return async (to, from, next) => {
    if(to.meta?.isPublic) {
        next()
    }
    if(UserProfileModel.exists()) {
        next()
    }

    if(store.getters["oidc/oidcUser"]?.usermanagementUuid){
      const res = await UserProfileModel.getOrFetchByUsermanagementUuid(
        store.getters["oidc/oidcUser"].usermanagementUuid
      );
      if(res){
        next();
      }else{
        next({ name: 'Error' })
      }
    }
  }
}