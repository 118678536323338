import SocioGrpcApi from "@socotec.io/socio-grpc-api";

const socioGrpcClient = new SocioGrpcApi(
  process.env.VUE_APP_SOCIO_GRPC_API_ENV,
  undefined,
  undefined,
  undefined,
  process.env.VUE_APP_SERVICE_SLUG
);

const initCarCheckAuth = (accessToken) => {
  socioGrpcClient.accessToken = accessToken;
};

export default socioGrpcClient;
export { socioGrpcClient, initCarCheckAuth };