const Incident = () =>
  import(/* webpackChunkName: "Incident" */ "@/components/carDamages/Incident");
const IncidentConfirmation = () =>
  import(/* webpackChunkName: "IncidentConfirmation" */ "@/components/carDamages/IncidentConfirmation");

  export default[
    {
        path:"/",
        name:"Incident",
        component : Incident
    },
    {
        path:"confirmation/:actionId",
        name:"IncidentConfirmation",
        component : IncidentConfirmation
    }
]