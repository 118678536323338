import appCarCheckClient from '@/setup/axios.js';
import Car from '@/models/Car';

const urlEntryPoint = `/car/cars`;

export const CarService = {
	async getCarByUserProfile(uuid) {
		const url = `${urlEntryPoint}/?linked_user_profile=${uuid}`;

		try {
			const results = await Car.api().get(url, { dataKey: 'results' });
			return results.response.data.results;
		} catch (error) {
			console.log('Errors', error);
		}
		return false;
	},
	async getCarById(id) {
		try {
			const response = await appCarCheckClient(`${urlEntryPoint}/${id}`);
			return response.data;
		} catch (error) {
			console.log('Errors', error);
		}
		return false;
	},
	async getCarByNumberPlate(numberPlate) {
		try {
			const response = await appCarCheckClient( `${urlEntryPoint}/?search=${numberPlate}`)
			return response.data.results;
		} catch (error) {
			console.log('Errors', error);
		}
		return false;
	}
};
