import axios from "axios";

let appCarCheckClient = axios.create({
  baseURL: process.env.VUE_APP_CAR_CHECK_BACK_URL,
  headers: {}
});

const initAppSafeAuth = accessToken => {
  appCarCheckClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default appCarCheckClient;
export { appCarCheckClient, initAppSafeAuth };
