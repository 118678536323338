import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
export const i18n = new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  silentFallbackWarn: true,
  messages: loadLocaleMessages()
});

const loadedLanguages = ["fr"]; // our default language that is prelaoded

const langToBCP47 = {
  fr: "fr-FR",
  en: "en-US"
};

Vue.prototype.$getLocaleBCPTag = () => {
  if (!i18n || !langToBCP47[i18n.locale]) {
    return "en-US";
  }
  return langToBCP47[i18n.locale];
};

export function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(
        /* webpackChunkName: "lang-[request]" */ `@/locales/${lang}`
      ).then(msgs => {
        i18n.setLocaleMessage(lang, msgs.default);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      });
    }
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
}
